var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "green-border-box outer-box" }, [
    _c("div", { staticClass: "inner-box" }, [
      _c("div", { staticClass: "grey-logo-circle" }, [
        _c("img", {
          staticClass: "img-in-circle",
          attrs: {
            src: _vm.brand.logo || _vm.$common.getDefaultImage("brand")
          },
          on: {
            error: function($event) {
              _vm.brand.logo = _vm.$common.getDefaultImage("brand")
            }
          }
        })
      ]),
      _c("span", { staticClass: "title", attrs: { title: _vm.brand.name } }, [
        _vm._v(_vm._s(_vm.brand.name))
      ]),
      _c("br"),
      _c(
        "span",
        { staticClass: "description", attrs: { title: _vm.brand.tagline } },
        [_vm._v(_vm._s(_vm.brand.tagline))]
      ),
      _c("br"),
      _c(
        "span",
        {
          staticClass: "description",
          attrs: { title: _vm.brand.approved_pickls_count }
        },
        [
          _vm._v("Completed Pickls: "),
          _c("strong", [_vm._v(_vm._s(_vm.brand.approved_pickls_count))])
        ]
      ),
      _c(
        "span",
        {
          staticClass: "description",
          attrs: { title: _vm.brand.approved_pickls_count }
        },
        [
          _vm._v("Registered On: "),
          _c("strong", [
            _vm._v(_vm._s(_vm._f("humanReadableDate")(_vm.brand.created_at)))
          ])
        ]
      ),
      _c("br"),
      _vm.userRole == "super-admin"
        ? _c("div", { staticClass: "text-center" }, [
            _vm.brand.status == "ACTIVE"
              ? _c(
                  "button",
                  {
                    staticClass: "btn-negative",
                    attrs: {
                      "data-target": "#deactivate-brand-popup",
                      "data-toggle": "modal",
                      type: "button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("setStatus", _vm.brand)
                      }
                    }
                  },
                  [_vm._v("DEACTIVATE")]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn-positive",
                    attrs: {
                      "data-target": "#deactivate-brand-popup",
                      "data-toggle": "modal",
                      type: "button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("setStatus", _vm.brand)
                      }
                    }
                  },
                  [_vm._v("ACTIVATE")]
                ),
            _c(
              "button",
              {
                staticClass: "btn-positive",
                staticStyle: { "margin-left": "15px" },
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.pushToBrandDetailsPage(_vm.brand.id)
                  }
                }
              },
              [_vm._v("VIEW")]
            )
          ])
        : _c("div", { staticClass: "text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn-positive",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.pushToBrandDetailsPage(_vm.brand.id)
                  }
                }
              },
              [_vm._v("VIEW")]
            )
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }