






















































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

export interface Brand {
  id: number;
  name: string;
  tagline: string;
  logo: string;
  status: string;
}

@Component
export default class BrandItem extends Vue {
  public userRole: any = 'staff'
  public userUrl:any = 'staff'
  @Prop() brand!: Brand;
  pushToBrandDetailsPage (brandId: number) {
    let userData = this.$store.state.userData
    let userUrl = 'staff'
    if (userData.role === 'super-admin') {
      userUrl = 'sadmin'
    }
    this.$router.push('/' + userUrl + '/brands/' + brandId)
  }

  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else {
      this.userUrl = 'staff'
    }
  }
}
