







































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class BulkDeletePicklPopup extends Vue {
  @Prop() popupId!: string
  @Prop() selectedBrandIds!: any

  deletePickl () {
    this.$http
      .post('brands/bulk-deactivate', { 'brandIds': this.selectedBrandIds }, {
        headers: {
          'user-type': 'sadmin'
        }
      })
      .then((response) => {
        let DeactivateBrandCloseButtonPopup: HTMLElement = this.$refs.DeactivateBrandCloseButtonPopup as HTMLElement
        this.$notify({ type: 'success', text: 'Selected brands has been deleted successfully!' })
        this.$emit('bulkPicklDeleted')
        DeactivateBrandCloseButtonPopup.click()
      })
  }
}
