<template>
  <div class="green-border-box in-padd white-bg">
    <div class="row">
      <input class="search-box" type="search" v-model="query" placeholder="Search brand">
      <button  class="btn-positive pull-right"  data-toggle="modal" @click="togglePicklContentSelectable">{{popButtonName}}</button>&nbsp;&nbsp;

      <select v-model="sort" class="pull-right">
        <option value="created_at-DESC" selected>Sign-up &#8595;</option>
        <option value="created_at-ASC">Sign-up &#8593;</option>
        <option value="name-ASC">A-Z</option>
        <option value="name-DESC">Z-A</option>
        <option value="approved_pickls_count-ASC">Pickls Created &#8593;</option>
        <option value="approved_pickls_count-DESC">PIckls Created &#8595;</option>
        <!-- <option value="completed_pickls_count-ASC">Pickls &#8593;</option>
        <option value="completed_pickls_count-DESC">Pickls &#8595;</option> -->
      </select>

      <select v-model="status" class="pull-right">
        <option value="">Status</option>
        <option value="ACTIVE">ACTIVE</option>
        <option value="INACTIVE">INACTIVE</option>
      </select>
    </div>
    <div class="row" v-if="selectableBrandDiv" style="margin-bottom: 10px;;">
      <div class="col-sm-12 green-border-box white-bg" style="padding:1%;">
        <div class="checklist-head pull-left">
          <input type="checkbox" id="all-pickls" v-model="selectAllBrands" @change="onSelectAll">
          <label for="all-pickls">Select All Brands</label>
        </div>
        &nbsp;<button  data-target="#bulk-delete-brand-popup"  data-toggle="modal" class="pull-right btn-negative" v-if="selectedBrandsList.length > 0" >DELETE SELECTED</button>&nbsp;&nbsp;
        &nbsp;<button  data-target="#bulk-deactivate-brand-popup"  data-toggle="modal" class="pull-right btn-negative" v-if="selectedBrandsList.length > 0" >DEACTIVATE SELECTED</button>&nbsp;&nbsp;

      </div>
    </div>
    <div class="row">
      <div
        :class="selectableBrandDiv?'selectable col-xs-12 col-sm-6 item-list':'col-xs-12 col-sm-6 item-list'"
        v-for="brand in $store.state.brands.data"
        :key="brand.id"
      >
        <BrandItem @click.native="pushToBrandDetailsPage(brand.id)" :brand="brand" @setStatus="actionBrand = brand" :class="isSelectedBrand(brand.id)?'bottom-margin brand-selected':'bottom-margin'"/>
      </div>
    </div>
    <div class="text-center">
      <h3 v-if="$store.state.brands.data==0 && !loadingBrands">No Brands Found in System!</h3>
      <h2 v-if="loadingBrands" style="text-align:center;">Loading Brands...</h2>
      <button
        v-if="!$store.getters.isBrandListComplete"
        class="btn-positive"
        @click="loadMore"
      >Load more</button>
    </div>
    <DeactivateBrandPopup
      popupId="deactivate-brand-popup"
      :brand="actionBrand"
      @statusChanged="updateBrandList"
    />
    <BulkDeleteBrandPopup :popupId="'bulk-delete-brand-popup'" :selectedBrandIds="selectedBrandsList" @bulkPicklDeleted="reFilter"/>
    <BulkDeactivateBrandPopup :popupId="'bulk-deactivate-brand-popup'" :selectedBrandIds="selectedBrandsList" @bulkPicklDeleted="reFilter"/>
  </div>
</template>

<script>
import BrandItem from '@/components/SAdmin/BrandItem.vue'
import DeactivateBrandPopup from '@/components/SAdmin/DeactivateBrandPopup.vue'
import BulkDeleteBrandPopup from '@/components/SAdmin/BulkDeleteBrandPopup.vue'
import BulkDeactivateBrandPopup from '@/components/SAdmin/BulkDeactivateBrandPopup.vue'

export default {
  name: 'Brand',
  components: {
    BrandItem,
    DeactivateBrandPopup,
    BulkDeleteBrandPopup,
    BulkDeactivateBrandPopup
  },
  data: function () {
    return {
      query: '',
      limit: 100,
      offset: 0,
      actionBrand: null,
      loadingBrands: true,
      sort: 'created_at-DESC',
      selectableBrandDiv: false,
      selectedBrandsList: [],
      selectAllBrands: false,
      status: 'ACTIVE'
    }
  },
  computed: {
    popButtonName () {
      if (this.selectableBrandDiv === true) {
        return 'CANCEL SELECT'
      } else {
        return 'SELECT'
      }
    }
  },
  methods: {
    reFilter () {
      this.selectedBrandsList = []
      this.selectableBrandDiv = false
      this.offset = 0
      this.$common.delay(this.getBrands, 500)()
    },
    isSelectedBrand (brandId) {
      if (this.selectedBrandsList.includes(brandId)) {
        return true
      } else {
        return false
      }
    },

    onSelectAll () {
      this.selectedBrandsList = []
      if (this.selectAllBrands) {
        this.$store.state.brands.data.forEach((brand) => {
          this.selectedBrandsList.push(brand.id)
        })
      }
    },
    pushToBrandDetailsPage (brandId) {
      if (this.selectableBrandDiv) {
        this.toggleSelectedBrandList(brandId)
      }
    },

    toggleSelectedBrandList (brandId) {
      if (this.selectedBrandsList.includes(brandId)) {
        this.selectedBrandsList.splice(this.selectedBrandsList.indexOf(brandId), 1)
      } else {
        this.selectedBrandsList.push(brandId)
      }
    },
    togglePicklContentSelectable () {
      this.selectableBrandDiv = !this.selectableBrandDiv
      if (this.selectableBrandDiv === false) {
        this.selectedPicklsList = []
        this.selectAllBrands = false
      }
    },
    getBrands (clear = true) {
      this.loadingBrands = true
      this.$store.dispatch('getBrandsList', { query: this.$data.query, limit: this.$data.limit, clear: clear, offset: this.$data.offset, sort: this.sort, status: this.status })
        .then(
          (response) => {
            this.loadingBrands = false
          })
    },
    loadMore () {
      this.$data.offset += this.$data.limit
      this.getBrands(false)
    },
    updateBrandList () {
      this.limit = 20
      this.offset = 0
      this.getBrands()
    }
  },
  watch: {
    query: function () {
      this.offset = 0
      this.$common.delay(this.getBrands, 500)()
    },
    sort: function () {
      this.offset = 0
      this.$common.delay(this.getBrands, 500)()
    },
    status: function () {
      this.offset = 0
      this.getBrands()
    }
  },
  created () {
    this.getBrands()
  }
}
</script>

<style scoped>
@media only screen and (max-width: 575px) {
  .item-list >>> .outer-box span {
    white-space: normal;
    word-wrap: break-word;
  }
}
.selectable {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}
.selectable > div {
  border: dotted 1px gray;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0) rotate(-0.1deg);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0) rotate(0.1deg);
  }
  30%, 50%, 70% {
    transform: translate3d(-1px, 0, 0) rotate(-0.1deg);
  }
  40%, 60% {
    transform: translate3d(1px, 0, 0) rotate(0.1deg);
  }
}

.brand-selected {
  box-shadow: 0px 0px 10px 2px #12db6d;
}
</style>
